import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function Login(props) {

  // If the user is already logged in, direct them to the chat room
  if(localStorage.getItem("user")) {
    props.history.push("/");
  }

  const [ user, setUser ] = useState({
    id: uuidv4(),
    color: randomColor()
  });

  function randomColor() {
    const allowedChars = "0123456789ABCDEF".split("");
    let color = "#";

    for(let i = 0; i < 3; i++) {
      color += allowedChars[Math.floor(Math.random() * allowedChars.length)] + "A";
    }

    return color;
  }
  
  function handleInputChange(e) {
    const updatedUser = {...user};
    updatedUser.name = e.target.value;
    setUser(updatedUser);
  }
  
  function handleSubmit(e) {
    e.preventDefault();

    if(user.name !== "") {
      localStorage.setItem("user", JSON.stringify(user));
      props.history.push("/");
    }
  }

  return (
    <div className="panel-flex panel-flex--single">
      <div className="panel">

        <form name="login" onSubmit={handleSubmit} autoComplete="off">
          <label htmlFor="name">What's your name?</label>
          <input type="text" name="name" className="input-field" autoFocus onChange={handleInputChange} />
          <input type="submit" value="Start Chatting" className="btn" />
        </form>
        
      </div>
    </div>
  );
}

export default withRouter(Login);