import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

function Participants(props) {

  const [ messages, setMessages ] = useState([]);
  const messageList = useRef(null);
  
  useEffect(() => {

    props.socket.on("load_messages", (data) => {
      setMessages(data);
      scrollToBottom();
    });

    props.socket.on("message", (msg) => {
      receivedMessage(msg);
      scrollToBottom();
    });

  }, [props.socket]);

  function receivedMessage(msg) {
    setMessages(oldMsgs => [...oldMsgs, msg]);
  }

  function scrollToBottom() {
    messageList.current.scrollTop = messageList.current.scrollHeight;
  }
  
  return (
    <div className="message-list" ref={messageList}>
      {messages.map((msg, idx) => {
        const bySameAuthorClassname = idx > 0 && messages[idx-1].author.id === msg.author.id ? "repeat-author" : "";
        return (
          <p className={`message ${bySameAuthorClassname}`} key={msg.id}>
            <span className="message__author">{msg.author.name}</span>
            <span className="message__text">{msg.text}</span>
          </p>
        );
      })}
    </div>
  );
}

export default withRouter(Participants);