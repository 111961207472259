import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();

export const SocketContextProvider = (props) => {

  const [ socketContext, setSocketContext ] = useState(null);
  
  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SERVER_HOST);
    setSocketContext({ socket });
    return () => socket.disconnect();
  }, []);

  return socketContext ? <SocketContext.Provider value={socketContext}>{props.children}</SocketContext.Provider> : null;
}

export const { Consumer } = SocketContext;