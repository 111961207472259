import React from "react";
import loading from "../img/loading.svg";

function Loading() {
  return (
    <div className="loading-spinner">
      <img src={loading} alt="Loading" />
    </div>
  );
}

export default Loading;