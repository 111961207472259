import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

// Components
import Messages from "../components/Chat/Messages";
import MessageComposer from "../components/Chat/MessageComposer";
import Participants from "../components/Chat/Participants";
import Loading from "../components/Loading";

// Contexts
import { SocketContext } from "../context/SocketContext";

function Chat(props) {

  // Get the socket context
  const socketContext = useContext(SocketContext);
  const { socket } = socketContext;
  
  const [ user ] = useState(JSON.parse(localStorage.getItem("user")) || null);

  // User joined
  useEffect(() => {
    socket.emit("user_joined", user);
  }, [socket, user]);
  
  // Make sure the user is logged in
  if(user === null) {
    props.history.push("/login");
  }
  
  if(user !== null) {
    return (
      <div className="panel-flex">

        <div className="panel panel--wide panel--no-padding chat-area">
          <Messages socket={socket} />
          <MessageComposer user={user} socket={socket} />
        </div>

        <Participants socket={socket} />
        
      </div>
    );
  } else {
    return <Loading/>;
  }
}

export default withRouter(Chat);