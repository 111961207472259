import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function MessageComposer(props) {

  const [ messageText, setMessageText ] = useState("");
  const messageField = useRef(null);

  useEffect(() => {

    // Automatically focus on the message field when the user starts typing
    document.addEventListener("keydown", focusIntoTextField);

    function focusIntoTextField() {
      if(document.activeElement !== messageField.current) {
        messageField.current.focus();
      }
    }

    return () => {
      document.removeEventListener("keydown", focusIntoTextField);
    };

  }, []);

  function handleMessageText(e) {
    setMessageText(e.target.value);
  }

  function sendMessage(e) {
    if(messageText !== "") {
      
      const newMessage = {
        id: uuidv4(),
        author: props.user,
        text: messageText,
        time: new Date().valueOf()
      };
      
      props.socket.emit("message", newMessage);
      setMessageText("");
    }

    e.preventDefault();
  }
  
  return (
    <div className="message-composer">
      <form name="message_form" autoComplete="off" onSubmit={sendMessage}>
        <input type="text" name="message" className="input-field" autoFocus ref={messageField} onChange={handleMessageText} value={messageText} />
        <input type="submit" value="Send" className="btn" onClick={() => messageField.current.focus()} />
      </form>
    </div>
  );
}

export default withRouter(MessageComposer);