import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";

// Contexts
import { SocketContextProvider } from "./context/SocketContext";

// Views
import Login from "./views/Login";
import Chat from "./views/Chat";

function App() {
  
  return (
    <BrowserRouter>
      <div className="app">
        <Switch>
          <Route exact path="/login" render={() => <Login/>} />
          <SocketContextProvider>
            <Route exact path="/" render={() => <Chat/>} />
          </SocketContextProvider>
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;