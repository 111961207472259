import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

function Participants(props) {

  const [ participants, setParticipants ] = useState([]);

  useEffect(() => {
    props.socket.on("participant_update", (data) => updateParticipants(data));
  }, [props.socket]);
  
  function updateParticipants(users) {
    setParticipants(users);
  }

  function logout() {
    localStorage.removeItem("user");
    props.history.push("/login");
  }
  
  return (
    <div className="panel panel--participant-list">
          
      <div className="participants">
      
        <h3>Participants</h3>

        <ul>
          {participants.map((usr) => {
            return (
              <li key={usr.id}>
                {usr.name}
              </li>
            );
          })}
        </ul>
        
      </div>
      
      <button className="btn btn--secondary" onClick={logout}>Log Out</button>
      
    </div>
  );
}

export default withRouter(Participants);